import './EconsultSubjectMeeting.less'

import { useLocation } from '@reach/router'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import Div100vh from 'react-div-100vh'

import { useScopedIntl } from '../../../../hooks'
import { DatacMessage, DatacTitle } from '../../../common'
import { EconsultEndMeetingModal } from '../EconsultEndMeetingModal'
import { EconsultMeeting } from '../EconsultMeeting'
import { EconsultMeetingContext, MeetingStateDataTypes } from '../EconsultMeetingContext'
import { EconsultMeetingEnded } from '../EconsultMeetingEnded'
import { EconsultWaitingRoom } from '../EconsultWaitingRoom'

export const EconsultSubjectMeeting: React.FC = () => {
  const eConsultIntl = useScopedIntl('studies.econsult')

  const {
    joinedToMeeting,
    isConfirmModalOpened,
    meetingStateDispatch,
    meetingEnded,
    handleConfirmEndMeeting,
    connectToMeetingSubject,
    room,
    isAcquiringLocalTracks,
    isConnecting
  } = useContext(EconsultMeetingContext)

  const location = useLocation()

  useEffect(() => {
    if (!location.search.includes('?uuid=')) {
      meetingStateDispatch({ type: MeetingStateDataTypes.SET_MEETING_ENDED, payload: true })
      DatacMessage.error(
        eConsultIntl('error_wrong_meeting_data_title'),
        eConsultIntl('error_wrong_meeting_data_description')
      )
    }

    const uuid = location.search.split('uuid=')[1]

    const startMeeting = async () => {
      await connectToMeetingSubject(uuid)
    }

    startMeeting()
  }, [])

  const MeetingStartedComponent = useCallback(() => {
    return joinedToMeeting ? <EconsultMeeting /> : <EconsultWaitingRoom />
  }, [joinedToMeeting])

  const isConnectedToRoom = useMemo(() => {
    return room && !isAcquiringLocalTracks && !isConnecting
  }, [room, isAcquiringLocalTracks, isConnecting])

  return (
    <>
      <Div100vh className="econsult-subject-meeting">
        {' '}
        {/* fixes 100vh issues on mobile */}
        {isConnecting && (
          <DatacTitle type="h3" className="econsult-subject-meeting__connecting">
            {eConsultIntl('connecting')}
          </DatacTitle>
        )}
        {isConnectedToRoom ? (
          <div className="econsult-subject-meeting__box">
            <MeetingStartedComponent />
          </div>
        ) : (
          meetingEnded && <EconsultMeetingEnded />
        )}
      </Div100vh>
      <EconsultEndMeetingModal
        content={eConsultIntl('disconnect_warning_subject')}
        isOpened={isConfirmModalOpened}
        onClose={() =>
          meetingStateDispatch({ type: MeetingStateDataTypes.SET_IS_CONFIRM_END_MODAL_OPENED, payload: false })
        }
        onSubmit={handleConfirmEndMeeting}
      />
    </>
  )
}
